export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title '>
          <h2>Team Features</h2>
        </div>
        <div className='col-md-10 col-md-offset-1 section-sub-title'>
          <h3>
            <div>We are a team of IT specialists, we make each job unique.</div>{' '}
            <div>
              We are recommended, having received the desired result in the form
              of profit or traffic.
            </div>{' '}
            <div>
              The team - with a soul approaches the development, embodying in it
              all our-your ideas and ideas
            </div>
          </h3>
        </div>
        <div className='row inline-block'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3 col-6'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
        <div className='row inline-block'>
          {props.data2
            ? props.data2.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3 col-6'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
